import { BreadcrumbsRootService, defineBreadcrumbsService } from '@adac-de/breadcrumbs-service'
import { createFeatureHub } from '@feature-hub/core'
import { loadFederatedModule } from '@feature-hub/module-loader-federation'
import { FeatureHubContextProvider } from '@feature-hub/react'
import {
  SerializedStateManagerV1,
  serializedStateManagerDefinition,
} from '@feature-hub/serialized-state-manager'
import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './app'
import { integratorId } from './config'
import { ADACServiceContext } from './service-context'

declare const window: {
  __BASE_URL__: string
}

type FeatureAppModuleSource = {
  readonly url: string
  readonly moduleType?: string
}

const getSerializedStatesFromDom = () => {
  const scriptElement = document.querySelector('script[type="x-feature-hub/serialized-states"]')
  return scriptElement?.textContent
}

const getUrlsForHydrationFromDom = (): FeatureAppModuleSource[] => {
  const scriptElement = document.querySelector('script[type="x-feature-hub/urls-for-hydration"]')

  if (!scriptElement?.textContent) {
    return []
  }

  return JSON.parse(scriptElement.textContent)
}

;(async () => {
  const baseUrl = window.__BASE_URL__

  const container = document.querySelector('#maut')
  const BreadcrumbsServiceDefinition = defineBreadcrumbsService()
  const { featureAppManager, featureServices } = createFeatureHub(integratorId, {
    featureServiceDefinitions: [serializedStateManagerDefinition, BreadcrumbsServiceDefinition],
    featureServiceDependencies: {
      [serializedStateManagerDefinition.id]: '^1.0.0',
      [BreadcrumbsServiceDefinition.id]: '^1.0.0',
    },
    moduleLoader: loadFederatedModule,
  })

  const breadcrumbs = featureServices[BreadcrumbsServiceDefinition.id] as BreadcrumbsRootService
  const urlsForHydration = getUrlsForHydrationFromDom()
  const serializedStateManager = featureServices[
    serializedStateManagerDefinition.id
  ] as SerializedStateManagerV1

  const serializedStates = getSerializedStatesFromDom()

  if (serializedStates) {
    serializedStateManager.setSerializedStates(serializedStates)
  }

  await Promise.all(
    urlsForHydration.map(async ({ url, moduleType }) =>
      featureAppManager.preloadFeatureApp(url, moduleType),
    ),
  )

  hydrateRoot(
    container!,
    <React.StrictMode>
      <FeatureHubContextProvider value={{ featureAppManager }}>
        <BrowserRouter>
          <ADACServiceContext.Provider value={{ breadcrumbs }}>
            <App baseUrl={baseUrl} />
          </ADACServiceContext.Provider>
        </BrowserRouter>
      </FeatureHubContextProvider>
    </React.StrictMode>,
  )
  // eslint-disable-next-line no-console
})().catch(console.error)
